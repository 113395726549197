<template>
  <v-card class="card pa-3 mx-2 flat-card" color="surface">
    <v-row>
      <v-col>
        <span class="medium-font onSurface--text">
          سفارش شما ظرف مدت {{is_express.time + ' ' + is_express.type}} پس از تایید نهایی قابل تحویل می باشد.
        </span>
      </v-col>
    </v-row>
    
    <v-row class="pa-2">
      <v-spacer></v-spacer>
      <v-btn @click="close_dialog" class="button onPrimaryActions--text primaryActions small-button">مرحله بعد</v-btn>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: ['express'],
  computed: {
    is_express () {
      let express = this.$props.express
      if (express.type == 1) {
        express.type = "روز"
      } else if (express.type == 2) {
        express.type = "ساعت"
      } else if (express.type == 3) {
        express.type = "دقیقه"
      }
      return express
    }
  },
  methods: {
    close_dialog () {
      localStorage.setItem('experss', "see")
      this.$emit('close_dialog', 0)
    }
  }
}
</script>