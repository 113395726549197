<template>
  <v-container>
    <navbar title="شیوه پرداخت" :route = "route_back"/>
    <v-main class="pt-15 d-flex flex-column justify-center"  style="padding-bottom: 90px">
      <error-alert/>
        <costs/>
        <loading-page v-if="loading"/>
    </v-main>
    <v-btn @click="payment" block class="fixed-btn button primaryActions onPrimaryActions--text py-6">پرداخت و اتمام فرایند خرید</v-btn>
    <div class="overlay" v-if="express_time.is_express == 1">
      <expressDialog @close_dialog='close_dialog' v-if="express_time.is_express == 1" :express="express_time"/>
    </div>
  </v-container>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import costs from './components/payment/paymentCostsCard'
import { viewbasket, submit_pay } from './models/Basket'
import LoadingPage from '@/components/tools/loadingPage.vue'
import { mapState } from 'vuex'
import store from '@/store'
import ErrorAlert from '@/components/alerts/error.alert.vue'
import expressDialog from './components/dialog/expressDialog'
export default {
  components: {
    navbar,
    costs,
    LoadingPage,
    ErrorAlert,
    expressDialog
  },
  data: () => ({
    express_time: {
      is_express: 0,
      time: 0,
      type: 0
    },
    route_back: "/send_time"
  }),
  created () {
    viewbasket()
    const express = localStorage.getItem('experss')
    if (express && express != "see") {
      this.express_time = JSON.parse(express)
      this.route_back = "/reciever"
    } else if (express) {
      this.route_back = "/reciever"
    }
  },
  destroyed () {
    store.dispatch("back_payment")
  },
  computed: {
    ...mapState({
      loading: state => state.loading_page,
      purses: state => state.basket.purses,
      coupons: state => state.basket.coupons,
      way_pay: state => state.basket.way_pay,
      payment_tah: state => state.basket.cost_payment
    })
  },
  methods: {
    close_dialog (close) {
      this.express_time.is_express = close
    },
    payment () {
      let coupon = ''
      let purses = []
      if (this.coupons) {
        coupon = this.coupons.id 
      }
      if (this.purses.length >= 1) {
        for (const purse of this.purses) {
          if (purse.use == true) {
            purses.push(purse.id)
          }
        }
      }
      if (this.payment_tah != 0) {
        if (!this.way_pay) {
          store.dispatch('alertError', {show: true, type: 'way_pay'})
          setTimeout(() => {
            store.dispatch('alertError', {show: false, type: 'way_pay'})
          }, 2500);
          return
        }
      }
      const payment = {
        coupons: coupon,
        purses: purses,
        pay_method: this.way_pay
      }
      debugger
      submit_pay(payment)
    }
  }
}
</script>